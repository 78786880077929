import React from 'react'
import classNames from 'classnames'
import parse from 'html-react-parser'
import * as styles from './style.module.scss'
import Cta from '~globals/Cta'

export const SpecialTeaser = ({ content }) => {
  const { cta, description, image, dark, link_target, title, topic, link_text, mailto } = content[0].data

  return (
    <div
      data-appear
      className={classNames(
        styles.SpecialTeaser,
        {[styles.dark]: dark}
      )}>
        <div
          className={styles.image}>
            <img src={image.url} alt={image.alt} />
        </div>
        <div className={styles.text}>
          <div className={styles.title}>
            {parse(title.html)}
          </div>
          <div className={styles.description}>
            {parse(description.html)}
          </div>
          <div className={styles.tag}>
            {topic}
          </div>
          <div className={styles.link}>
            {link_text?.text && link_target?.url && (
              <Cta
                color="#F22B32"
                text={link_text.text}
                url={link_target.url}
              />
            )}
            {mailto && (
              <a className={styles.cta} href={`mailto:${mailto}`}>{cta}</a>
            )}
          </div>
        </div>
    </div>
  )
}