import React from 'react'
import parse from 'html-react-parser'
import { graphql, useStaticQuery } from 'gatsby'

export const Order = () => {
  const { prismicOrder } = useStaticQuery(graphql`
    query OrderQuery {
      prismicOrder {
        data {
          api
        }
      }
    }
  `)

  const { api } = prismicOrder.data

  return (
    <section className="Order">
      {parse(api)}
      <div data-theme="light" id="flipdish-menu" data-offset="150" data-restaurant="fd6275"></div><script id="flipdish-script" type="text/javascript" charset="UTF-8" src="https://web-order.flipdish.co/client/productionwlbuild/latest/static/js/main.js"></script>
    </section>
  )
}