// extracted by mini-css-extract-plugin
export var TextSection = "style-module--TextSection--c21dd";
export var active = "style-module--active--d3f92";
export var ctaMixin = "style-module--ctaMixin--3bb53";
export var large = "style-module--large--a8d41";
export var tagMixin = "style-module--tagMixin--f6f42";
export var typeBodyL = "style-module--typeBodyL--429bb";
export var typeBodyM = "style-module--typeBodyM--0082c";
export var typeBodyXl = "style-module--typeBodyXl--ab5c5";
export var typeCaption = "style-module--typeCaption--5a6fd";
export var typeCta = "style-module--typeCta--5aada";
export var typeH4 = "style-module--typeH4--a7f96";
export var typeH5 = "style-module--typeH5--1ea8b";
export var typeHeadlineL = "style-module--typeHeadlineL--2fb7d";
export var typeHeadlineM = "style-module--typeHeadlineM--31b5f";
export var typeHeadlineXL = "style-module--typeHeadlineXL--07c4b";
export var typeHeadlineXXL = "style-module--typeHeadlineXXL--5060c";
export var typeMedium = "style-module--typeMedium--93a4d";
export var typeMenu = "style-module--typeMenu--712ed";
export var typeTag = "style-module--typeTag--042ae";
export var typeheadlineXsEmphasised = "style-module--typeheadlineXsEmphasised--06fc9";
export var wrap = "style-module--wrap--86267";
export var ytVideo = "style-module--ytVideo--d9898";