import * as styles from './style.module.scss'
import classNames from 'classnames'
import Facts from '~images/icons/Icon-Info.svg'
import parse from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import Resa from '~images/icons/Icon-Ingredients.svg'
import uniqid from 'uniqid'

export const Dish = ({ data, productCopy, tags, salad = null }) => {
  const [isMobile, setIsMobile] = useState(false)
  const [showIngredients, setShowIngredients] = useState(false)
  const [showAllergies, setShowAllergies] = useState(false)
  const { allergies, title, description, image, ingredients, product_tipp } = data

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true)
    }
  }, [])

  return (
    <div
      className={classNames(styles.product, styles.Dish, {[styles.Salad]: salad})}
      data-appear
    >
      <div className={styles.flex}>
        <div className={styles.wrapLeft}>
          <h4 className={styles.productTitle}>{title.text}</h4>
          <div className={styles.productTags}>
            {data.tags.length && data.tags.map(({ tag }) => {
              const t = tags.filter(item => item.prismicId === tag.id)[0]?.data.name.text
              return (
                <button
                  key={uniqid()}
                  className={classNames(styles.tag)}
                >
                  {t}
                </button>
              )
            })}
          </div>
          <div data-html className={styles.productDescription}>
            {parse(description.html)}
          </div>
          {product_tipp.text && (
            <div>
              <div className={styles.productMatch}>
                <span>👉</span>
                <p>
                  {productCopy.matching.text} {product_tipp.text}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className={styles.visual}>
          <div className={styles.holder}>
            <div className={styles.flex}>
              <img src={Resa} alt="Ingredients" />
              <div>
                <button
                  className={styles.ingredientsLabel}
                  onClick={() => setShowIngredients(!showIngredients)}
                  onKeyDown={() => setShowIngredients(!showIngredients)}
                >
                  {productCopy.ingredients_label.text}
                </button>
                <div className={classNames(styles.ingredients, {[styles.visible]: showIngredients})}>
                  {ingredients.length && ingredients.map(({ ingredient}) => (
                    <span key={uniqid()}>{ingredient}</span>
                  ))}
                  {isMobile && (
                    <div>
                      <p className={styles.productAllergyLabel}>
                        {productCopy.allergy_1.text}
                      </p>
                      <p className={classNames(styles.ingredients, styles.productAllergyList, {[styles.visible]: showAllergies})}>
                        {allergies.length && allergies.map(({ allergy }, index) => (
                          <span key={uniqid()}>{allergy}{index === allergies.length - 1 ? '.' : ', '}</span>
                        ))}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!isMobile && allergies[0]?.allergy  && (
              <div className={classNames(styles.flex, styles.allergies)}>
                <img src={Facts} alt="Info" />
                <div>
                  <button
                    className={styles.productAllergyLabel}
                    onClick={() => setShowAllergies(!showAllergies)}
                    onKeyDown={() => setShowAllergies(!showAllergies)}
                  >
                    {productCopy.allergy_1.text}
                  </button>
                  <p className={classNames(styles.ingredients, styles.productAllergyList, {[styles.visible]: showAllergies})}>
                    {allergies.length && allergies.map(({ allergy }, index) => (
                      <span key={uniqid()}>{allergy}{index === allergies.length - 1 ? '.' : ', '}</span>
                    ))}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className={styles.image}>
            <img src={image.url} alt={image.alt} />
          </div>
        </div>
      </div>
    </div>
  )
}