export const handleAppear = () => {
  const container = document.querySelector('#___gatsby')
  const { scrollTop } = container
  const sections = document.querySelectorAll('[data-appear]')

  sections.forEach(section => {
    const offset = section.dataset.appearDelay ? parseInt(section.dataset.appearDelay) : 200
    if (scrollTop >= section.offsetTop - window.innerHeight + offset && !section.dataset.inView) {
      section.dataset.inView = true
    }
  })
}