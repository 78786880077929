// extracted by mini-css-extract-plugin
export var Page = "style-module--Page--fdbc1";
export var active = "style-module--active--95dd2";
export var ctaMixin = "style-module--ctaMixin--9a290";
export var tagMixin = "style-module--tagMixin--43fb1";
export var typeBodyL = "style-module--typeBodyL--5b492";
export var typeBodyM = "style-module--typeBodyM--20fae";
export var typeBodyXl = "style-module--typeBodyXl--bc125";
export var typeCaption = "style-module--typeCaption--51772";
export var typeCta = "style-module--typeCta--8b169";
export var typeH4 = "style-module--typeH4--1de73";
export var typeH5 = "style-module--typeH5--d22b5";
export var typeHeadlineL = "style-module--typeHeadlineL--c1c90";
export var typeHeadlineM = "style-module--typeHeadlineM--b17ad";
export var typeHeadlineXL = "style-module--typeHeadlineXL--2b2bb";
export var typeHeadlineXXL = "style-module--typeHeadlineXXL--73de0";
export var typeMedium = "style-module--typeMedium--2129e";
export var typeMenu = "style-module--typeMenu--26d89";
export var typeTag = "style-module--typeTag--7fc2c";
export var typeheadlineXsEmphasised = "style-module--typeheadlineXsEmphasised--b0a5f";