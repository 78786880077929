import React, { useEffect, useState } from 'react'
import * as styles from './style.module.scss'
import Container from '~globals/Container'
import Cta from '~globals/Cta'
import parse from 'html-react-parser'
import { graphql, Link, useStaticQuery } from 'gatsby'
import uniqid from 'uniqid'

const Footer = () => {
  const [lang, setLang] = useState(null)

  useEffect(() => {
    setLang(window.location.pathname.includes('/de/') ? 'de' : 'en')
  }, [])

  const { footerDE, footerEN } = useStaticQuery(graphql`
    query FooterQuery {
      footerEN: allPrismicFooter(filter: {lang: {eq: "en-eu"}}) {
        nodes {
          data {
            copyright {
              html
            }
            cta {
              text
            }
            image {
              alt
              url
            }
            logo {
              alt
              url
            }
            navigation {
              link {
                url
                document {
                  ... on PrismicPage {
                    data {
                      navigation_name {
                        text
                      }
                    }
                  }
                }
              }
            }
            navigation_info {
              link {
                url
                document {
                  ... on PrismicPage {
                    data {
                      navigation_name {
                        text
                      }
                    }
                  }
                }
              }
            }
            socials {
              icon {
                alt
                url
              }
              link {
                url
              }
            }
            cta_link {
              url
            }
          }
        }
      }
      footerDE: allPrismicFooter(filter: {lang: {eq: "de-de"}}) {
        nodes {
          data {
            copyright {
              html
            }
            cta {
              text
            }
            image {
              alt
              url
            }
            logo {
              alt
              url
            }
            navigation {
              link {
                url
                document {
                  ... on PrismicPage {
                    data {
                      navigation_name {
                        text
                      }
                    }
                  }
                }
              }
            }
            navigation_info {
              link {
                url
                document {
                  ... on PrismicPage {
                    data {
                      navigation_name {
                        text
                      }
                    }
                  }
                }
              }
            }
            socials {
              icon {
                alt
                url
              }
              link {
                url
              }
            }
            cta_link {
              url
            }
          }
        }
      }
    }
  `)

  const {
    copyright,
    cta,
    cta_link,
    image,
    logo,
    navigation,
    navigation_info,
    socials
  } = lang === 'en' ? footerEN.nodes[0].data : footerDE.nodes[0].data

  return (
    <div className={styles.Footer}>
      <Container>
        <nav>
          <ul className={styles.navigation}>
            {navigation?.length && navigation.map(({ link }) => (
              <li key={uniqid()}>
                <Link to={link.url}>
                  {link.document.data.navigation_name.text}
                </Link>
              </li>
            ))}
          </ul>
          <div className={styles.centerWrap}>
            <div>
              <Link className={styles.logo} to="/">
                <img src={logo.url} alt={logo.alt} />
              </Link>
              <div className={styles.info}>
                {parse(copyright.html)}
                <ul>
                  {navigation_info?.length && navigation_info.map(({ link }) => (
                    <li key={uniqid()}>
                      <Link to={link.url}>
                        {link.document.data.navigation_name.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.social}>
                {socials?.length > 0 && socials.map(({ icon, link }) => (
                  <Link className={styles.socialItem} to={link.url}>
                    <img src={icon.url} />
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.ctaWrap}>
            <Cta
              color="#F22B32"
              text={cta.text}
              url={cta_link.url}
            />
          </div>
        </nav>
      </Container>
      <img className={styles.image} src={image.url} alt={image.alt} />
    </div>
  )
}

export default Footer
