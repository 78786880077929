// extracted by mini-css-extract-plugin
export var active = "style-module--active--9d446";
export var altChoice = "style-module--altChoice--11d21";
export var ctaMixin = "style-module--ctaMixin--da158";
export var icon = "style-module--icon--0bda5";
export var languageSwitcher = "style-module--languageSwitcher--af0f0";
export var list = "style-module--list--6f6ef";
export var option = "style-module--option--1e24c";
export var tagMixin = "style-module--tagMixin--bf306";
export var typeBodyL = "style-module--typeBodyL--7a58c";
export var typeBodyM = "style-module--typeBodyM--9fa68";
export var typeBodyXl = "style-module--typeBodyXl--1419e";
export var typeCaption = "style-module--typeCaption--b8136";
export var typeCta = "style-module--typeCta--81bb5";
export var typeH4 = "style-module--typeH4--c3bca";
export var typeH5 = "style-module--typeH5--f181d";
export var typeHeadlineL = "style-module--typeHeadlineL--54eb3";
export var typeHeadlineM = "style-module--typeHeadlineM--877b7";
export var typeHeadlineXL = "style-module--typeHeadlineXL--28fbb";
export var typeHeadlineXXL = "style-module--typeHeadlineXXL--1bae1";
export var typeMedium = "style-module--typeMedium--f9590";
export var typeMenu = "style-module--typeMenu--faef7";
export var typeTag = "style-module--typeTag--819a3";
export var typeheadlineXsEmphasised = "style-module--typeheadlineXsEmphasised--ed7d4";