import { Card } from '~components/Card'
import { Hero } from '~components/Hero'
import { JobList } from '~components/JobList'
import { Locations } from '~components/Locations'
import { Order } from '~components/Order'
import { Seo } from '~globals/Seo'
import { Teaser } from '~globals/Teaser'
import { SpecialTeaser } from '~globals/SpecialTeaser'
import { TextSection } from '~components/content/TextSection'
import { ContactForm } from '~globals/ContactForm'
import { FranchiseForm } from '~globals/FranchiseForm'
import * as styles from './style.module.scss'
import Container from '~globals/Container'
import React from 'react'
import uniqid from 'uniqid'

const Page = ({
  pageContext: {
    card,
    categories,
    contactForm,
    countries,
    franchiseForm,
    global,
    heros,
    jobCategories,
    jobContracts,
    jobList,
    locations,
    locationsCopy,
    page: { data },
    productCopy,
    products,
    specialTeasers,
    tags,
    teasers,
    textSections,
  },
}) => {
  const { sections } = data

  return (
    <div className={styles.Page}>
      <Container>
        {sections.map(({ section }) => {
          let sectionEl
          switch (section.type) {
            case 'hero':
              const content = heros.filter(item => item.prismicId === section.id)
              sectionEl = (
                <Hero
                  content={content}
                  key={uniqid()}
                />
              )
              break;
            case 'text_section':
              const textContent = textSections.filter(item => item.prismicId === section.id)
              sectionEl = (
                <TextSection
                  content={textContent}
                  key={uniqid()}
                />
              )
              break;
            case 'teaser':
              const teaser = teasers.filter(item => item.prismicId === section.id)
              sectionEl = (
                <Teaser
                  content={teaser}
                  key={uniqid()}
                />
              )
              break;
            case 'special_teaser':
              const specialTeaser = specialTeasers.filter(item => item.prismicId === section.id)
              sectionEl = (
                <SpecialTeaser
                  content={specialTeaser}
                  key={uniqid()}
                />
              )
              break;
            case 'card':
              sectionEl = (
                <Card
                  card={card}
                  categories={categories}
                  global={global}
                  key={uniqid()}
                  productCopy={productCopy.data}
                  products={products}
                  tags={tags}
                />
              )
              break;
            case 'locations':
              sectionEl = (
                <Locations
                  key={uniqid()}
                  countries={countries}
                  locationsCopy={locationsCopy}
                  locations={locations}
                />
              )
              break;
            case 'order':
              sectionEl = (
                <Order key={uniqid()} />
              )
              break;
            case 'job_list':
              sectionEl = (
                <JobList
                  global={global}
                  jobCategories={jobCategories}
                  jobContracts={jobContracts}
                  jobList={jobList}
                  key={uniqid()}
                  locations={locations}
                />
              )
              break;
            case 'contact_form':
              sectionEl = (
                <ContactForm
                  data={contactForm.data}
                  key={uniqid()}
                />
              )
              break;
            case 'franchise_form':
              sectionEl = (
                <FranchiseForm
                  data={franchiseForm.data}
                  key={uniqid()}
                />
              )
              break;

              default:
              sectionEl = null
          }
          return sectionEl
        })}
      </Container>
    </div>
  )
}

export default Page

export const Head = ({
  pageContext: {
    page: { data },
  },
}) => {
  const { sharing_description, sharing_image, sharing_title } = data

  return (
    <Seo
      sharingTitle={`${sharing_title.text} | Shiso Burger`}
      sharingDescription={sharing_description.text}
      sharingImage={sharing_image.url}
    />
  )
}
