// extracted by mini-css-extract-plugin
export var SpecialTeaser = "style-module--SpecialTeaser--96333";
export var active = "style-module--active--e79ce";
export var cta = "style-module--cta--f29a8";
export var ctaMixin = "style-module--ctaMixin--1d6f1";
export var dark = "style-module--dark--4125b";
export var description = "style-module--description--f6061";
export var image = "style-module--image--a85a9";
export var link = "style-module--link--365d8";
export var tag = "style-module--tag--8fa6d";
export var tagMixin = "style-module--tagMixin--202b6";
export var text = "style-module--text--eccb9";
export var title = "style-module--title--e3310";
export var typeBodyL = "style-module--typeBodyL--e6ef5";
export var typeBodyM = "style-module--typeBodyM--30338";
export var typeBodyXl = "style-module--typeBodyXl--140b6";
export var typeCaption = "style-module--typeCaption--03b41";
export var typeCta = "style-module--typeCta--54306";
export var typeH4 = "style-module--typeH4--4d45e";
export var typeH5 = "style-module--typeH5--381b2";
export var typeHeadlineL = "style-module--typeHeadlineL--ced29";
export var typeHeadlineM = "style-module--typeHeadlineM--53bf4";
export var typeHeadlineXL = "style-module--typeHeadlineXL--24b18";
export var typeHeadlineXXL = "style-module--typeHeadlineXXL--2d31c";
export var typeMedium = "style-module--typeMedium--27ae0";
export var typeMenu = "style-module--typeMenu--3c7f5";
export var typeTag = "style-module--typeTag--6541a";
export var typeheadlineXsEmphasised = "style-module--typeheadlineXsEmphasised--84bc9";