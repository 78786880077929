import React from 'react'
import classNames from 'classnames'
import parse from 'html-react-parser'
import * as styles from './style.module.scss'
import Cta from '~globals/Cta'

export const Teaser = ({ content }) => {
  const { description, image, image_double, left, link_target, title, type, topic, link_text } = content[0].data

  return (
    <div
      data-appear
      data-appear-delay="0"
      className={classNames(
        styles.Teaser,
        {[styles.regular]: type === 'regular'},
        {[styles.large]: type === 'large'},
        {[styles.double]: type === 'double'},
        {[styles.left]: left}
      )}>
        <div
          className={styles.image}>
            <img src={image.url} alt={image.alt} />
        </div>
        <div className={styles.text}>
          <div className={styles.title}>
            {parse(title.html)}
          </div>
          <div className={styles.description}>
            {parse(description.html)}
          </div>
          <div className={styles.tag}>
            {topic}
          </div>
          {link_text?.text && link_target?.url && (
            <div className={styles.link}>
              <Cta
                color="#F22B32"
                text={link_text.text}
                url={link_target.url}
              />
            </div>
          )}
        </div>
        {type === 'double' && (
          <div
            className={classNames(styles.image, styles.imageLarge)}>
            <img src={image_double.url} alt={image_double.alt} />
          </div>
        )}
    </div>
  )
}