import React, { useEffect, useState } from 'react'
import * as styles from './style.module.scss'
import { LanguageSwitcher } from '~components/LanguageSwitcher'
import classNames from 'classnames'
import Container from '~globals/Container'
import { graphql, Link, useStaticQuery } from 'gatsby'
import uniqid from 'uniqid'

const Menu = ({ location }) => {
  const [lang, setLang] = useState('en')
  const { pathname } = location

  useEffect(() => {
    setLang(window.location.pathname.includes('/de/') ? 'de' : 'en')
  }, [])

  const { menuDE, menuEN } = useStaticQuery(graphql`
    query MenuQuery {
      menuEN: allPrismicMenu(filter: {lang: {eq: "en-eu"}}) {
        nodes {
          data {
            logo {
              alt
              url
            }
            navigation {
              link {
                url
              }
              link_text
            }
          }
        }
      }
      menuDE: allPrismicMenu(filter: {lang: {eq: "de-de"}}) {
        nodes {
          data {
            logo {
              alt
              url
            }
            navigation {
              link {
                url
              }
              link_text
            }
          }
        }
      }
    }
  `)

  const { logo, navigation } = lang === 'en' ? menuEN.nodes[0].data : menuDE.nodes[0].data

  return (
    <div className={styles.Menu}>
      <Container>
        <nav>
          <Link to={lang === 'de' ? '/de/' : '/'}>
            <img className={styles.logo} src={logo.url} alt={logo.alt} />
          </Link>
          <ul>
            {navigation?.length && navigation.map(({ link, link_text }) => (
              <li key={uniqid()}>
                {link.url.includes('http') && (
                  <a
                    className={
                      classNames({
                        [styles.active]: pathname.includes(link.url),
                      })}
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link_text}
                  </a>
                )}
                {!link.url.includes('http') && (
                  <Link
                    className={
                      classNames({
                        [styles.active]: pathname.includes(link.url),
                      })}
                    to={link.url}
                  >
                    {link_text}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <LanguageSwitcher />
      </Container>
    </div>
  )
}

export default Menu
