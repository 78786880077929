import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import parse from 'html-react-parser'
import * as styles from './style.module.scss'
import Resa from '~images/icons/Icon-Ingredients.svg'
import Facts from '~images/icons/Icon-Info.svg'
import uniqid from 'uniqid'

export const Side = ({ data, productCopy, tags }) => {
  const [isMobile, setIsMobile] = useState(false)
  const [showIngredients, setShowIngredients] = useState(false)
  const [showAllergies, setShowAllergies] = useState(false)

  const { allergies, title, description, image, ingredients } = data

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true)
    }
  }, [])

  return (
    <div
      className={classNames(styles.product, styles.Side)}
      data-appear
    >
      <div className={styles.wrapLeft}>
        <h4 className={styles.productTitle}>{title.text}</h4>
        <div className={styles.productTags}>
          {data.tags.length && data.tags.map(({ tag }) => {
            const t = tags.filter(item => item.prismicId === tag.id)[0]?.data.name.text
            return (
              <button
                key={uniqid()}
                className={classNames(styles.tag)}
              >
                {t}
              </button>
            )
          })}
        </div>
        <div data-html className={styles.productDescription}>
          {parse(description.html)}
        </div>
      </div>
      <div className={styles.visual}>
        <div className={styles.image}>
          <img src={image.url} alt={image.alt} />
        </div>
        <div className={styles.holder}>
          <div className={styles.flex}>
            <img src={Resa} alt="Ingredients" />
            <div>
              <button
                className={styles.ingredientsLabel}
                onClick={() => setShowIngredients(!showIngredients)}
                onKeyDown={() => setShowIngredients(!showIngredients)}
              >
                {productCopy.ingredients_label.text}
              </button>
              <div className={classNames(styles.ingredients, {[styles.visible]: showIngredients})}>
                {ingredients.length && ingredients.map(({ ingredient}) => (
                  <span key={uniqid()}>{ingredient}</span>
                ))}
                {isMobile && allergies[0]?.allergy && (
                  <div>
                    <p className={styles.productAllergyLabel}>
                      {productCopy.allergy_1.text}
                    </p>
                    <p className={classNames(styles.ingredients, styles.productAllergyList, {[styles.visible]: showAllergies})}>
                      {allergies.length && allergies.map(({ allergy }, index) => (
                        <span key={uniqid()}>{allergy}{index === allergies.length - 1 ? '.' : ', '}</span>
                      ))}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!isMobile && allergies[0]?.allergy  && (
            <div className={classNames(styles.flex, styles.allergies)}>
              <img src={Facts} alt="Info" />
              <div>
                <button
                  className={styles.productAllergyLabel}
                  onClick={() => setShowAllergies(!showAllergies)}
                  onKeyDown={() => setShowAllergies(!showAllergies)}
                >
                  {productCopy.allergy_1.text}
                </button>
                <p className={classNames(styles.ingredients, styles.productAllergyList, {[styles.visible]: showAllergies})}>
                  {allergies.length && allergies.map(({ allergy }, index) => (
                    <span key={uniqid()}>{allergy}{index === allergies.length - 1 ? '.' : ', '}</span>
                  ))}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}