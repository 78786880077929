import React, { useEffect, useState } from 'react'
import * as styles from './style.module.scss'
import classNames from 'classnames'

export const LanguageSwitcher = () => {
  const [lang, setLang] = useState(null)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true)
    }
  }, [])

  useEffect(() => {
    setLang(window.location.pathname.includes('/de/') ? 'de' : 'en')
  }, [])

  const handleLangChange = e => {
    if (e.keyCode !== 9) {
      if (e.target.dataset.lang === 'de' && lang !== 'de') {
        window.location = window.location.origin + '/de' + window.location.pathname
      } else if (lang !== 'en' && e.target.dataset.lang === 'en') {
        window.location = window.location.href.replace('de/', '')
      }
    }
  }

  return (
    <div className={styles.languageSwitcher}>
      <div className={styles.list}>
        <button
          className={classNames(styles.option, {[styles.altChoice]: lang !== 'en'})}
          data-lang="en"
          onClick={handleLangChange}
          onKeyDown={handleLangChange}
        >
          {isMobile ? 'EN' : 'English'}
        </button>
        <button
          className={classNames(styles.option, {[styles.altChoice]: lang !== 'de'})}
          data-lang="de"
          onClick={handleLangChange}
          onKeyDown={handleLangChange}
        >
          {isMobile ? 'DE' : 'Deutsch'}
        </button>
      </div>
      <svg className={styles.icon} width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 10V1H11L7.66667 5.5L11 10H1Z" stroke="black" strokeWidth="1.5"/>
      </svg>
    </div>
  )
}