import { Dish } from './Dish'
import { handleAppear } from '~utils/handleAppear'
import { Side } from './Side'
import * as styles from './style.module.scss'
import classNames from 'classnames'
import parse from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import uniqid from 'uniqid'

export const Card = ({ card, categories, global, products, productCopy, tags }) => {
  const [category, setCategory] = useState('Burger')
  const [activeTags, setActiveTags] = useState([])
  const [activeBurgers, setActiveBurgers] = useState([])
  const [burgersList, setBurgersList] = useState([])
  const [saladsList, setSaladsList] = useState([])
  const [sidesList, setSidesList] = useState([])

  const { headline, menu_intro, burgers, salads, sides } = card.data

  const setTag = id => {
    if (activeTags.includes(id)) {
      setActiveTags(activeTags.filter(t => t !== id))
    } else {
      setActiveTags(oldArray => [...oldArray, id])
    }
  }

  const getUrlCategory = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const type = urlParams.get('type')

    if (type) {
      setCategory(type)
    }
  }

  useEffect(() => {
    setBurgersList(burgers.map(({ item }) =>
      products.filter(p => p.prismicId === item.id)
    ))
    setSaladsList(salads.map(({ item }) =>
      products.filter(p => p.prismicId === item.id)
    ))
    setSidesList(sides.map(({ item }) =>
      products.filter(p => p.prismicId === item.id)
    ))

    getUrlCategory()
  }, [burgers, salads, sides, products])

  const updateCategory = type => {
    setCategory(type)
    window.history.replaceState(null, "New Page parameter", window.location.pathname + `?type=${type}`)
  }

  useEffect(() => {
    if (!activeTags.length) {
      setActiveBurgers(burgersList)
    } else {
      const newsList = burgersList.filter(p => p[0].data.tags.some(t => activeTags.includes(t.tag.id)))
      setActiveBurgers(newsList)
    }
  }, [activeTags, burgersList])

  useEffect(() => {
    setTimeout(() => {
      handleAppear()
    }, 100)
  }, [activeBurgers, category])

  return (
    <div className={styles.Card}>
      <div data-appear className={styles.header}>
        <div className={styles.headline}>
          {parse(headline.html)}
        </div>
        <div className={styles.description}>
          {parse(menu_intro?.html)}
        </div>

        <div className={styles.categories}>
          {categories.map(cat  => (
            <button
              key={uniqid()}
              onClick={() => updateCategory(cat.data.type)}
              onKeyDown={() => updateCategory(cat.data.type)}
              className={classNames(styles.category, {[styles.active]: cat.data.type === category})}
            >
              <div className={styles.categoryItem}>
                <p>{cat.data.name.text}</p>
                <img src={cat.data.image.url} alt={cat.data.image.alt} />
              </div>
            </button>
          ))}
        </div>
      </div>

      <div className={classNames(styles.tags, {[styles.visible]: category === 'Burger'})}>
        {tags.map(tag => (
          <button
            onClick={() => setTag(tag.prismicId)}
            key={uniqid()}
            className={classNames(styles.tag, {[styles.active]: activeTags.includes(tag.prismicId)} )}
          >
            {tag.data.name.text}
          </button>
        ))}
      </div>

      <div className={classNames(styles.list, {[styles.grid]: category === 'Side'})}>
        {category === 'Burger' && activeBurgers.map(burger => (
          <Dish
            data={burger[0]?.data}
            isVisible={({isVisible}) => isVisible}
            key={uniqid()}
            productCopy={productCopy}
            products={products}
            tags={tags}
          />
        ))}
        {category === 'Side' && sidesList.map(side => (
          <Side
            data={side[0]?.data}
            key={uniqid()}
            productCopy={productCopy}
            tags={tags}
          />
        ))}
        {category === 'Salad' && saladsList.map(salad => (
          <Dish
            data={salad[0]?.data}
            key={uniqid()}
            productCopy={productCopy}
            products={products}
            salad
            tags={tags}
          />
        ))}
        {category === 'Burger' && activeBurgers.length === 0 && (
          <p className="noResult">
            {global.data.no_result.text}
          </p>
        )}
      </div>
    </div>
  )
}

export default Card
