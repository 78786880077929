import React, { useEffect, useState } from 'react'
import * as styles from './style.module.scss'
import classNames from 'classnames'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { globalHistory } from '@reach/router'
import uniqid from 'uniqid'

const BottomMenu = ({ location }) => {
  const [showMore, setShowMore] = useState(false)
  const [lang, setLang] = useState(null)
  const { pathname } = location

  useEffect(() => {
    setLang(window.location.pathname.includes('/de/') ? 'de' : 'en')
  }, [])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setShowMore(false)
    })
  }, [setShowMore])

  const { menuDE, menuEN } = useStaticQuery(graphql`
    query BottomMenuQuery {
      menuEN: allPrismicBottomMenu(filter: {lang: {eq: "en-eu"}}) {
        nodes {
          data {
            more {
              link {
                url
              }
              title
            }
            order_icon {
              alt
              url
            }
            more_icon {
              alt
              url
            }
            more_label
            navigation {
              link {
                url
                document {
                  ... on PrismicPage {
                    data {
                      icon {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              title
            }
            second_navigation {
              link {
                url
              }
              link_text
            }
          }
        }
      }
      menuDE: allPrismicBottomMenu(filter: {lang: {eq: "de-de"}}) {
        nodes {
          data {
            more {
              link {
                url
              }
              title
            }
            order_icon {
              alt
              url
            }
            more_icon {
              alt
              url
            }
            more_label
            navigation {
              link {
                url
                document {
                  ... on PrismicPage {
                    data {
                      icon {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              title
            }
            second_navigation {
              link {
                url
              }
              link_text
            }
          }
        }
      }
    }
  `)

  const {
    more_icon,
    more_label,
    more,
    navigation,
    order_icon,
    second_navigation,
  } = lang === 'en' ? menuEN.nodes[0].data : menuDE.nodes[0].data

  return (
    <div className={styles.BottomMenu}>
      <div className={classNames(styles.more, {[styles.visible]: showMore})}>
        <button
          className={styles.close}
          onClick={() => setShowMore(false)}
          onKeyDown={() => setShowMore(false)}
        >
          <div />
          <div />
        </button>
        <nav>
          {more?.length && (
            <ul>
              {more?.length && more.map(({ link, title }) => (
                <li
                  className={classNames(styles.link, {[styles.active]: pathname.includes(link.url)})}
                  key={uniqid()}
                >
                  {link.url.includes('http') && (
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {title}
                    </a>
                  )}
                  {!link.url.includes('http') && (
                    <Link to={link.url}>
                      {title}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          )}
          {second_navigation.length && (
            <ul className={styles.secondNavigation}>
              {second_navigation.map(({ link, link_text }) => (
                <li key={uniqid()}>
                  {link?.url?.includes('http') && (
                    <a
                      className={
                        classNames({
                          [styles.active]: pathname.includes(link.url),
                        })}
                      href={link.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link_text}
                    </a>
                  )}
                  {!link.url && (
                    <a
                      href={`mailto:test@gmail.com`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link_text}
                    </a>
                  )}
                  {link?.url && !link?.url?.includes('http') && (
                    <Link
                      className={
                        classNames({
                          [styles.active]: pathname.includes(link.url),
                        })}
                      to={link.url}
                    >
                      {link_text}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          )}
        </nav>
      </div>
      <nav className={styles.container}>
        {navigation?.length && navigation.map(({ link, title }) => (
          <li
            className={classNames(styles.item, {[styles.active]: pathname.includes(link.url)})}
            key={uniqid()}
          >
            {!link.url.includes('http') && (
              <Link to={link.url}>
                <img src={link.document?.data.icon.url} alt={link.document?.data.icon.alt}  />
                <p>{title}</p>
              </Link>
            )}
            {link.url.includes('http') && (
                <a
                className={
                  classNames({
                    [styles.active]: pathname.includes(link.url),
                  })}
                href={link.url}
                target="_blank"
                rel="noreferrer"
              >
                <img src={order_icon.url} alt={order_icon.alt}  />
                {title}
              </a>
            )}
          </li>
        ))}
        {more_icon && more_label && (
          <button
            className={styles.item}
            onClick={() => setShowMore(true)}
            onKeyDown={() => setShowMore(true)}
            key={uniqid()}
          >
            <div>
              <img src={more_icon.url} alt={more_icon.alt}  />
              <p>{more_label}</p>
            </div>
          </button>
        )}
      </nav>
    </div>
  )
}

export default BottomMenu
