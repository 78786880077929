// extracted by mini-css-extract-plugin
export var BottomMenu = "style-module--BottomMenu--ba4b4";
export var active = "style-module--active--dcba9";
export var close = "style-module--close--27d72";
export var container = "style-module--container--c6d00";
export var ctaMixin = "style-module--ctaMixin--418d9";
export var item = "style-module--item--aa9c0";
export var link = "style-module--link--c4a38";
export var more = "style-module--more--f24c2";
export var secondNavigation = "style-module--secondNavigation--e9727";
export var tagMixin = "style-module--tagMixin--f1b76";
export var typeBodyL = "style-module--typeBodyL--c642e";
export var typeBodyM = "style-module--typeBodyM--fb7cf";
export var typeBodyXl = "style-module--typeBodyXl--7ce09";
export var typeCaption = "style-module--typeCaption--0d45a";
export var typeCta = "style-module--typeCta--41fd7";
export var typeH4 = "style-module--typeH4--64df9";
export var typeH5 = "style-module--typeH5--3c04b";
export var typeHeadlineL = "style-module--typeHeadlineL--6c651";
export var typeHeadlineM = "style-module--typeHeadlineM--3ce23";
export var typeHeadlineXL = "style-module--typeHeadlineXL--25e26";
export var typeHeadlineXXL = "style-module--typeHeadlineXXL--687aa";
export var typeMedium = "style-module--typeMedium--77a93";
export var typeMenu = "style-module--typeMenu--a72d0";
export var typeTag = "style-module--typeTag--44c32";
export var typeheadlineXsEmphasised = "style-module--typeheadlineXsEmphasised--9a2ef";
export var visible = "style-module--visible--ffc62";