import * as React from 'react'
import * as styles from './style.module.scss'
import Facts from '~images/icons/Icon-Info.svg'
import Loca from '~images/icons/Icon-Pin.svg'
import parse from 'html-react-parser'
import Resa from '~images/icons/Icon-Reservation.svg'
import uniqid from 'uniqid'
import Web from '~images/icons/Icon-Globe.svg'
import classNames from 'classnames'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export const Location = ({ copy, data, larger = null }) => {
  const { address, facts, google_maps, image_b2c, reservation, title, website } = data
  const { copy_directions, copy_facts, copy_reservations } = copy
  const image = getImage(image_b2c)

  return (
    <div
      id={title.text}
      data-appear
      data-location
      className={classNames(styles.Location, {[styles.larger]: larger})}
    >
      <div className={styles.image}>
        {image ? (
          <GatsbyImage image={image} alt={image_b2c.alt} />
        ): (
          <img src={image_b2c.url} alt={image_b2c.alt} />
        )
        }
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          {parse(title.html)}
        </div>

        {address[0]?.street_address && (
          <div className={styles.row}>
            <img className={styles.icon} src={Loca} alt="Location icon" />
            <div itemScope="itemscope" itemType="http://data-vocabulary.org/Address">
              <span itemProp="street-address">{address[0]?.street_address}</span><br/>
              <span itemProp="locality">{address[0]?.locality}</span>,&nbsp;
              <span itemProp="postal-code">{address[0]?.postal_code}</span><br/>
              <span itemProp="country-name">{address[0]?.country_name}</span>
            </div>
          </div>
        )}

        {google_maps.url && (
          <div className={styles.directions}>
            <a href={google_maps.url} target="_blank" rel="noreferrer">
              {copy_directions}
            </a>
          </div>
        )}

        {(reservation[0]?.link.html.length > 0 || reservation[0]?.telephone || reservation[0]?.email) && (
          <div className={styles.row}>
            <img className={styles.icon} src={Resa} alt="Reservation icon" />
            <div className={styles.reservations}>
              <p className={styles.label}>
                {copy_reservations}
              </p>
              <ul>
                {reservation[0]?.link?.html && (
                  <li>{parse(reservation[0]?.link.html)}</li>
                )}
                {reservation[0]?.telephone && (
                  <li>
                    <a
                      className={styles.telephone}
                      href={`tel:${reservation[0]?.telephone}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {reservation[0]?.telephone}
                    </a>
                  </li>
                )}
                {reservation[0]?.email && (
                  <li>
                    <a
                      href={`mailto:${reservation[0]?.email}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {reservation[0]?.email}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}

        {facts.length > 0 && (
          <div className={styles.row}>
            <img className={styles.icon} src={Facts} alt="Information icon" />
            <div>
              <p className={styles.label}>
                {copy_facts}
              </p>
              <ul>
                {facts.map(({ fact }) => (
                  <li key={uniqid()}>{fact}</li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {website.url && (
          <div className={styles.row}>
            <img className={styles.icon} src={Web} alt="Website icon" />
            <div>
              <a href={website.url} target="_blank" rel="noreferrer">
                {website.url}
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
