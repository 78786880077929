import React, { useEffect, useRef } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from '!mapbox-gl'
import * as styles from './style.module.scss'

mapboxgl.accessToken = 'pk.eyJ1IjoibWFwYm94LXNoaXNvIiwiYSI6ImNsODAzMmN4aDAyOWUzcnJ6bmkwamQyeGYifQ.N0azpiFCtyKtEvySXGjcXQ'

export const InteractiveMap = ({ data }) => {
  const mapContainer = useRef(null)
  const map = useRef(null)
  const { style, latitude, locations, longitude, zoom } = data

  useEffect(() => {
    if (map.current) return
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: style,
        center: [longitude, latitude],
        zoom: zoom
      });

      locations.forEach(loc => {
        const popup = new mapboxgl.Popup({ offset: 25 })
        .setHTML(loc.text.html)
        const marker = new mapboxgl.Marker()
        .setLngLat([loc.x, loc.y])
        .setPopup(popup)
        marker.addTo(map.current)
      })
  });

  return (
    <div
      ref={mapContainer}
      className={styles.map}
    />
  )
}

export default InteractiveMap
