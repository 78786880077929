import { Location } from '~components/Location'
import * as styles from './style.module.scss'
import classNames from 'classnames'
import parse from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import uniqid from 'uniqid'
import { handleAppear } from '~utils/handleAppear'

export const Locations = ({ countries, locationsCopy, locations }) => {
  const [activeCountries, setActiveCountries] = useState([])
  const [activeLocations, setActiveLocations] = useState([])

  const setCountry = id => {
    if (activeCountries.includes(id)) {
      setActiveCountries(activeCountries.filter(t => t !== id))
    } else {
      setActiveCountries(oldArray => [...oldArray, id])
    }
  }

  useEffect(() => {
    setTimeout(() => {
      handleAppear()
    }, 100)
  }, [activeCountries])

  useEffect(() => {
    if (!activeCountries.length) {
      setActiveLocations(locations)
    } else {
      const newsList = locations.filter(location => activeCountries.includes(location.data.country.id))
      setActiveLocations(newsList)
    }
  }, [activeCountries, locations])

  return (
    <section className="Locations">
      <div className={styles.description}>
        {parse(locationsCopy.data.description.html)}
      </div>
      <div data-countries-label className={styles.countriesLabel}>
        {parse(locationsCopy.data.country_label.html)}
      </div>
      <div>
        {countries && countries.map(country => (
          <button
            className={classNames(styles.tag, {[styles.active]: activeCountries.includes(country.prismicId)} )}
            data-tag
            key={uniqid()}
            onClick={() => setCountry(country.prismicId)}
          >
            {country.data.name}
          </button>
        ))}
      </div>
      <div className={styles.locationList}>
        {activeLocations.map(location => (
          <Location
            copy={locationsCopy.data}
            data={location.data}
            key={uniqid()}
          />
        ))}
      </div>
    </section>
  )
}
