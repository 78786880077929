// extracted by mini-css-extract-plugin
export var Card = "style-module--Card--bf8e5";
export var Dish = "style-module--Dish--69ae0";
export var Salad = "style-module--Salad--0d225";
export var Side = "style-module--Side--7f653";
export var active = "style-module--active--b8345";
export var allergies = "style-module--allergies--8493f";
export var allergyInfo = "style-module--allergyInfo--030a5";
export var categories = "style-module--categories--b16d7";
export var category = "style-module--category--bfa3d";
export var categoryItem = "style-module--categoryItem--eb976";
export var ctaMixin = "style-module--ctaMixin--47fa7";
export var description = "style-module--description--775dd";
export var filterLabel = "style-module--filterLabel--f3fe6";
export var flex = "style-module--flex--2561e";
export var grid = "style-module--grid--18436";
export var header = "style-module--header--bfb1c";
export var headline = "style-module--headline--ffea4";
export var holder = "style-module--holder--ccf10";
export var image = "style-module--image--811ff";
export var ingredients = "style-module--ingredients--ff150";
export var ingredientsLabel = "style-module--ingredientsLabel--a8ae0";
export var list = "style-module--list--72d40";
export var product = "style-module--product--5a76c";
export var productAllergy = "style-module--productAllergy--93ddf";
export var productAllergyLabel = "style-module--productAllergyLabel--661ce";
export var productAllergyList = "style-module--productAllergyList--1b6ab";
export var productDescription = "style-module--productDescription--e31aa";
export var productMatch = "style-module--productMatch--cd191";
export var productTags = "style-module--productTags--fb139";
export var productTitle = "style-module--productTitle--0e013";
export var tag = "style-module--tag--f0209";
export var tagMixin = "style-module--tagMixin--79f46";
export var tags = "style-module--tags--b3c7b";
export var typeBodyL = "style-module--typeBodyL--ce797";
export var typeBodyM = "style-module--typeBodyM--2f753";
export var typeBodyXl = "style-module--typeBodyXl--4aac8";
export var typeCaption = "style-module--typeCaption--7615d";
export var typeCta = "style-module--typeCta--27127";
export var typeH4 = "style-module--typeH4--57b23";
export var typeH5 = "style-module--typeH5--3aa60";
export var typeHeadlineL = "style-module--typeHeadlineL--df04d";
export var typeHeadlineM = "style-module--typeHeadlineM--3b3af";
export var typeHeadlineXL = "style-module--typeHeadlineXL--bae45";
export var typeHeadlineXXL = "style-module--typeHeadlineXXL--45a95";
export var typeMedium = "style-module--typeMedium--9e553";
export var typeMenu = "style-module--typeMenu--18ea0";
export var typeTag = "style-module--typeTag--3c740";
export var typeheadlineXsEmphasised = "style-module--typeheadlineXsEmphasised--605a0";
export var visible = "style-module--visible--92878";
export var visual = "style-module--visual--09a6d";
export var wrapLeft = "style-module--wrapLeft--e1771";