import React, { useEffect } from 'react'
import Menu from '~globals/Menu'
import BottomMenu from '~globals/BottomMenu'
import Footer from '~globals/Footer'
import { handleAppear } from '~utils/handleAppear'
// import { graphql, useStaticQuery } from 'gatsby'
import '~styles/imports/font-face.scss'
import '~styles/app.scss'
import * as styles from './style.module.scss'

const Layout = ({ children, location }) => {
  // const {
  //   pages: { nodes },
  // } = useStaticQuery(graphql`
  //   {
  //     pages: allSitePage {
  //       nodes {
  //         path
  //       }
  //     }
  //   }
  // `)

  useEffect(() => {
    // const routeMatch = nodes.filter(n => n.path === location.pathname || n.path + '/' === location.pathname)

    // console.log('filter', routeMatch.length)

    // if (routeMatch.length === 0) {
    //   console.log(window.location)
    //   window.location.replace(`${origin}/404`)
    // }

    const container = document.querySelector('#___gatsby')
    handleAppear()
    container.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    const container = document.querySelector('#___gatsby')

    container.addEventListener('scroll', handleAppear)
    handleAppear()

    return () => {
      container.removeEventListener('scroll', handleAppear)
    }
  }, [])

  return (
    <>
      <div className="layout">
        <Menu location={location} />
        <main className={styles.main}>{children}</main>
        <BottomMenu location={location} />
        <Footer />
      </div>
    </>
  )
}

export default Layout
