import { useEffect, useRef } from 'react'
import * as React from 'react'
import * as styles from './style.module.scss'
import classNames from 'classnames'
import parse from 'html-react-parser'
import uniqid from 'uniqid'

export const TextSection = ({ content }) => {
  const section = useRef(null)
  const data = content[0]?.data ? content[0].data : content

  const generateCaption = () => {
    if (section?.current) {
      const imgs = section.current.querySelectorAll('img')
      imgs.forEach(image => {
        const caption = document.createElement('caption')
        caption.innerHTML = image.alt
        image.parentNode.append(caption)

        if (window.innerWidth > 767) {
          setTimeout(() => {
            caption.style.width = `${image.offsetHeight/1.27}px`
          }, 600)
        }
      })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      generateCaption()
    }, 600)
  }, [section])

  return (
    <section ref={section} className={classNames(styles.TextSection, {[styles.large]: data.large})}>
      <div data-html data-text-section className={styles.wrap}>
        {data.content && data.content.map(({ element, video, youtube_video }) => (
          <div key={uniqid()}>
            {parse(element.html)}
            {video?.url && (
              <video src={video.url} controls />
            )}
            {youtube_video && (
              <div className={styles.ytVideo}>
                <iframe src={`https://www.youtube.com/embed/${youtube_video}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            )}
          </div>
        ))}
        {content.html && parse(content.html)}
      </div>
    </section>
  )
}
