exports.linkResolver = doc => {
  switch (doc.type) {
    case 'homepage': {
      return doc.lang === 'de-de' ? "/" : `/${doc.lang}`
    }
    case 'page': {
      const slug = doc.slug.startsWith('de') ? `/${doc.slug.replace('de', 'de/')}` : `/${doc.slug}`
      return slug
    }
    case 'location': {
      const lang = doc.lang === 'de-de' ? '/de' : ''
      const slug = '/location/' + doc.uid
      return lang + slug
    }

    default:
      return '/'
  }
}
