// extracted by mini-css-extract-plugin
export var active = "style-module--active--19409";
export var countriesLabel = "style-module--countriesLabel--7b256";
export var ctaMixin = "style-module--ctaMixin--b5f9a";
export var description = "style-module--description--ee45f";
export var locationList = "style-module--locationList--dee6a";
export var tag = "style-module--tag--06e99";
export var tagMixin = "style-module--tagMixin--f9c82";
export var typeBodyL = "style-module--typeBodyL--52576";
export var typeBodyM = "style-module--typeBodyM--b6c0c";
export var typeBodyXl = "style-module--typeBodyXl--b7c87";
export var typeCaption = "style-module--typeCaption--95b76";
export var typeCta = "style-module--typeCta--df1e6";
export var typeH4 = "style-module--typeH4--8c23a";
export var typeH5 = "style-module--typeH5--0e921";
export var typeHeadlineL = "style-module--typeHeadlineL--2b606";
export var typeHeadlineM = "style-module--typeHeadlineM--84495";
export var typeHeadlineXL = "style-module--typeHeadlineXL--be7dc";
export var typeHeadlineXXL = "style-module--typeHeadlineXXL--5ca9f";
export var typeMedium = "style-module--typeMedium--c55c4";
export var typeMenu = "style-module--typeMenu--9f390";
export var typeTag = "style-module--typeTag--66dd0";
export var typeheadlineXsEmphasised = "style-module--typeheadlineXsEmphasised--8c20b";