// extracted by mini-css-extract-plugin
export var Menu = "style-module--Menu--8dd1a";
export var active = "style-module--active--73dc4";
export var ctaMixin = "style-module--ctaMixin--02cef";
export var logo = "style-module--logo--17889";
export var tagMixin = "style-module--tagMixin--e30d2";
export var typeBodyL = "style-module--typeBodyL--73657";
export var typeBodyM = "style-module--typeBodyM--ddede";
export var typeBodyXl = "style-module--typeBodyXl--2ee4c";
export var typeCaption = "style-module--typeCaption--acbab";
export var typeCta = "style-module--typeCta--81253";
export var typeH4 = "style-module--typeH4--84a87";
export var typeH5 = "style-module--typeH5--f80da";
export var typeHeadlineL = "style-module--typeHeadlineL--8c45c";
export var typeHeadlineM = "style-module--typeHeadlineM--f8bd3";
export var typeHeadlineXL = "style-module--typeHeadlineXL--af339";
export var typeHeadlineXXL = "style-module--typeHeadlineXXL--b4d76";
export var typeMedium = "style-module--typeMedium--bbc26";
export var typeMenu = "style-module--typeMenu--13b56";
export var typeTag = "style-module--typeTag--9a2ed";
export var typeheadlineXsEmphasised = "style-module--typeheadlineXsEmphasised--08a69";