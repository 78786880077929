// extracted by mini-css-extract-plugin
export var Teaser = "style-module--Teaser--ff0d4";
export var active = "style-module--active--404a8";
export var ctaMixin = "style-module--ctaMixin--8a8fc";
export var description = "style-module--description--6ac7f";
export var double = "style-module--double--d8203";
export var image = "style-module--image--fdb8f";
export var imageLarge = "style-module--imageLarge--610c0";
export var large = "style-module--large--71aa6";
export var left = "style-module--left--24109";
export var link = "style-module--link--0028e";
export var regular = "style-module--regular--d5656";
export var tag = "style-module--tag--c3f04";
export var tagMixin = "style-module--tagMixin--f730b";
export var text = "style-module--text--e7c82";
export var title = "style-module--title--27bca";
export var typeBodyL = "style-module--typeBodyL--79602";
export var typeBodyM = "style-module--typeBodyM--85a5a";
export var typeBodyXl = "style-module--typeBodyXl--8b74c";
export var typeCaption = "style-module--typeCaption--38185";
export var typeCta = "style-module--typeCta--eb134";
export var typeH4 = "style-module--typeH4--a8d5b";
export var typeH5 = "style-module--typeH5--6a385";
export var typeHeadlineL = "style-module--typeHeadlineL--ccff9";
export var typeHeadlineM = "style-module--typeHeadlineM--e43b8";
export var typeHeadlineXL = "style-module--typeHeadlineXL--088d2";
export var typeHeadlineXXL = "style-module--typeHeadlineXXL--07b38";
export var typeMedium = "style-module--typeMedium--c2593";
export var typeMenu = "style-module--typeMenu--ae2a5";
export var typeTag = "style-module--typeTag--7b7a4";
export var typeheadlineXsEmphasised = "style-module--typeheadlineXsEmphasised--40d46";