import React, { useRef, useState } from 'react'
import parse from 'html-react-parser'
import axios from 'axios'
import classNames from 'classnames'
import * as styles from './style.module.scss'
import uniqid from 'uniqid'

export const FranchiseForm = ({ data }) => {
  const inputFile = useRef(null)
  const [filesNames, setFilesNames] = useState([])
  const [success, setSuccess] = useState(null)

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  })

  // console.log('server state: ', serverState)
  const { email, file, message, name, no_file, privacy, send, title } = data

  const handleFile = () => {
    const newArray = []
    Array.from(inputFile?.current.files)?.forEach(f => {
      newArray.push(f.name)
    })
    setFilesNames(newArray)
  }

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      // form.reset()
      setSuccess(true)
    }
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    console.log(form)
    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/441468ac-9e8b-4a48-a06c-ac9953244988",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }

  return (
    <form
      onSubmit={handleOnSubmit}
      className={styles.FranchiseForm}
      encType="multipart/form-data"
    >
      <div className={classNames(styles.formContent, {[styles.success]: success})}>
        <div className={styles.title}>
          {parse(title.html)}
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.input}>
            <input
              className={styles.inputText}
              name="Name"
              placeholder={name?.text}
              required
              type="text"
            />
          </div>
          <div className={styles.input}>
            <input
              className={styles.inputText}
              name="Email"
              placeholder={email?.text}
              required
              type="email"
            />
          </div>
        </div>
        <div className={classNames(styles.textArea, styles.input)}>
          <textarea
            className={styles.inputText}
            placeholder={message?.text}
            name="Message"
          />
        </div>
        <div className={classNames(styles.inputFile)}>
          <label htmlFor="file" className={styles.inputText}>{file?.text}</label>
          <p className={styles.filesDisplay}>
            {!filesNames.length && no_file?.text}
            {filesNames.length !== 0 && filesNames.map(f => <span key={uniqid()}>{f}</span>)}
          </p>
          <input
            onChange={handleFile}
            ref={inputFile}
            id="file"
            type="file"
            multiple
            name="files[]"
          />
        </div>
        <div className={styles.lastRow}>
          <div data-html className={styles.privacy}>
            <input type="checkbox" name="privacy" id="privacy" required />
            <label htmlFor="privacy">{parse(privacy.html)}</label>
          </div>
          <button className={styles.submitButton} type="submit">
            {send?.text}
          </button>
        </div>
      </div>
      <div className={classNames(styles.loading, {[styles.active]: serverState.submitting})}>
        <p>Loading …</p>
      </div>
      <div className={classNames(styles.successMessage, {[styles.active]: success})}>
        <p>Thank you for your message!<br/> We will be in touch soon</p>
      </div>
    </form>
  )
}
