import React from 'react'
import classNames from 'classnames'
import * as styles from './style.module.scss'
import { Link } from 'gatsby'

const Cta = ({ anchor = null, extraClassName, text, url }) => {

  const handleAnchor = e => {
    const target = document.querySelector(`#${anchor}`)

    if (anchor) {
      e.preventDefault()
      target?.scrollIntoView()
    }
  }

  return (
    <>
      {!anchor && (
        <Link
          className={classNames(styles.Cta, extraClassName)}
          to={'/' + url === '/' ? '' : url}
        >
          {text}
        </Link>
      )}
      {anchor && (
        <button
          onClick={handleAnchor}
          className={classNames(styles.Cta, extraClassName)}
        >
          {text}
        </button>
      )}
    </>
  )
}

export default Cta
