// extracted by mini-css-extract-plugin
export var active = "style-module--active--258be";
export var ctaMixin = "style-module--ctaMixin--279ad";
export var map = "style-module--map--bd3f7";
export var tagMixin = "style-module--tagMixin--8c60a";
export var typeBodyL = "style-module--typeBodyL--791f4";
export var typeBodyM = "style-module--typeBodyM--32cb4";
export var typeBodyXl = "style-module--typeBodyXl--dfcf9";
export var typeCaption = "style-module--typeCaption--52fc7";
export var typeCta = "style-module--typeCta--2dcfd";
export var typeH4 = "style-module--typeH4--701a4";
export var typeH5 = "style-module--typeH5--dc98c";
export var typeHeadlineL = "style-module--typeHeadlineL--d5c0b";
export var typeHeadlineM = "style-module--typeHeadlineM--2c2b8";
export var typeHeadlineXL = "style-module--typeHeadlineXL--ba472";
export var typeHeadlineXXL = "style-module--typeHeadlineXXL--1cc73";
export var typeMedium = "style-module--typeMedium--8a2f3";
export var typeMenu = "style-module--typeMenu--89efb";
export var typeTag = "style-module--typeTag--0b533";
export var typeheadlineXsEmphasised = "style-module--typeheadlineXsEmphasised--4bb7c";