import React from 'react'
import { useSiteMetadata } from '~utils/use-site-metadata'

export const Seo = ({ sharingTitle, sharingDescription, pathname, children, sharingImage }) => {
  const { title: defaultTitle, description: defaultDescription, image, siteUrl } = useSiteMetadata()

  const seo = {
    title: sharingTitle || defaultTitle,
    description: sharingDescription || defaultDescription,
    image: sharingImage || `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`
  }

  return (
    <>
      {/* Primary Meta Tags */}
      <title>{seo.title}</title>
      <meta name="title" content={seo.title} />
      <meta name="description" content={seo.description} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" itemProp="image" content={seo.image} />
      <meta property="og:site_name" content={seo.title} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={seo.url} />
      <meta property="twitter:title" content={seo.title} />
      <meta property="twitter:description" content={seo.description} />
      <meta property="twitter:image" content={seo.image} />

      {children}
    </>
  )
}
