import * as styles from './style.module.scss'
import classNames from 'classnames'
import parse from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import { InteractiveMap } from '~components/InteractiveMap'

export const Hero = ({ content }) => {
  const { headline, image, overlay, mapbox, type, video } = content[0].data
  const [mediaLoaded, setMediaLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (!mediaLoaded) {
        setMediaLoaded(true)
      }
    }, window.innerWidth < 768 ? 300 : 1500);
  }, [])

  return (
    <div data-appear className={classNames(
      styles.Hero,
      {
        [styles.mediaLoaded]: mediaLoaded,
        [styles.large]: type === 'L',
        [styles.medium]: type === 'M',
        [styles.hasVideo]: video?.url,
        [styles.noImage]: !image?.url && !mapbox.document && !video?.url,
        [styles.map]: mapbox.document
      }
    )}>
      <div className={classNames(styles.imageWrapper, {[styles.overlay]: overlay})}>
        {image?.url && !video?.url && (
          <div className={styles.image}>
            <img
              alt={image.alt}
              onLoad={() => setMediaLoaded(true)}
              src={image.url}
            />
          </div>
        )}
        {mapbox.document && (
          <InteractiveMap data={mapbox.document.data} />
        )}
        {video?.url && (
          <video
            autoPlay
            className={styles.video}
            loop
            muted
            onLoadedData={() => setMediaLoaded(true)}
            onCanPlay={() => setMediaLoaded(true)}
          >
            <source src={video.url} type="video/mp4" />
          </video>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.headline}>
          {parse(headline.html)}
        </div>
      </div>
    </div>
  )
}

export default Hero
