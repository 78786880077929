// extracted by mini-css-extract-plugin
export var Location = "style-module--Location--506db";
export var active = "style-module--active--eea00";
export var content = "style-module--content--088e0";
export var ctaMixin = "style-module--ctaMixin--b7205";
export var directions = "style-module--directions--cc124";
export var icon = "style-module--icon--4b1db";
export var image = "style-module--image--ad059";
export var label = "style-module--label--66fe2";
export var larger = "style-module--larger--823cb";
export var reservations = "style-module--reservations--ac926";
export var row = "style-module--row--e7677";
export var tagMixin = "style-module--tagMixin--a4da0";
export var telephone = "style-module--telephone--cd944";
export var title = "style-module--title--aa8fa";
export var typeBodyL = "style-module--typeBodyL--4f7ea";
export var typeBodyM = "style-module--typeBodyM--f821e";
export var typeBodyXl = "style-module--typeBodyXl--3aa84";
export var typeCaption = "style-module--typeCaption--88978";
export var typeCta = "style-module--typeCta--ebfae";
export var typeH4 = "style-module--typeH4--7af24";
export var typeH5 = "style-module--typeH5--197a7";
export var typeHeadlineL = "style-module--typeHeadlineL--9b6e5";
export var typeHeadlineM = "style-module--typeHeadlineM--8841f";
export var typeHeadlineXL = "style-module--typeHeadlineXL--76a6a";
export var typeHeadlineXXL = "style-module--typeHeadlineXXL--385ec";
export var typeMedium = "style-module--typeMedium--681ff";
export var typeMenu = "style-module--typeMenu--5f13d";
export var typeTag = "style-module--typeTag--ce561";
export var typeheadlineXsEmphasised = "style-module--typeheadlineXsEmphasised--4679e";