// extracted by mini-css-extract-plugin
export var Footer = "style-module--Footer--209f7";
export var active = "style-module--active--45bcf";
export var centerWrap = "style-module--centerWrap--6d15a";
export var ctaMixin = "style-module--ctaMixin--f3861";
export var ctaWrap = "style-module--ctaWrap--2ae52";
export var image = "style-module--image--db845";
export var info = "style-module--info--35a20";
export var logo = "style-module--logo--c6897";
export var navigation = "style-module--navigation--49ee4";
export var social = "style-module--social--d2d23";
export var socialItem = "style-module--socialItem--e3fb5";
export var tagMixin = "style-module--tagMixin--af3aa";
export var typeBodyL = "style-module--typeBodyL--774a8";
export var typeBodyM = "style-module--typeBodyM--1d60b";
export var typeBodyXl = "style-module--typeBodyXl--35f1e";
export var typeCaption = "style-module--typeCaption--5d01e";
export var typeCta = "style-module--typeCta--93d3f";
export var typeH4 = "style-module--typeH4--1429f";
export var typeH5 = "style-module--typeH5--72a07";
export var typeHeadlineL = "style-module--typeHeadlineL--4fea3";
export var typeHeadlineM = "style-module--typeHeadlineM--11d82";
export var typeHeadlineXL = "style-module--typeHeadlineXL--c9885";
export var typeHeadlineXXL = "style-module--typeHeadlineXXL--8d9cc";
export var typeMedium = "style-module--typeMedium--efec9";
export var typeMenu = "style-module--typeMenu--56428";
export var typeTag = "style-module--typeTag--94f8b";
export var typeheadlineXsEmphasised = "style-module--typeheadlineXsEmphasised--65ddb";