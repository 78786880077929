// extracted by mini-css-extract-plugin
export var Cta = "style-module--Cta--55042";
export var active = "style-module--active--d6601";
export var ctaMixin = "style-module--ctaMixin--8d2c1";
export var tagMixin = "style-module--tagMixin--c8fc7";
export var typeBodyL = "style-module--typeBodyL--51435";
export var typeBodyM = "style-module--typeBodyM--dab65";
export var typeBodyXl = "style-module--typeBodyXl--13e23";
export var typeCaption = "style-module--typeCaption--4b2ff";
export var typeCta = "style-module--typeCta--18d27";
export var typeH4 = "style-module--typeH4--2a4a9";
export var typeH5 = "style-module--typeH5--1dbe0";
export var typeHeadlineL = "style-module--typeHeadlineL--e0f47";
export var typeHeadlineM = "style-module--typeHeadlineM--2dacc";
export var typeHeadlineXL = "style-module--typeHeadlineXL--3b4ae";
export var typeHeadlineXXL = "style-module--typeHeadlineXXL--50458";
export var typeMedium = "style-module--typeMedium--70391";
export var typeMenu = "style-module--typeMenu--4f83f";
export var typeTag = "style-module--typeTag--9a758";
export var typeheadlineXsEmphasised = "style-module--typeheadlineXsEmphasised--1ed93";